//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formResourceAdd } from '@/api/modular/flowable/formResourceManage'
import { flowableCategoryList } from '@/api/modular/flowable/categoryManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage.js'

export default {
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      flowableCategoryListData: [],
      flowableTypeListData: [],
      form: this.$form.createForm(this),
      recordData: {}
    }
  },
  methods: {
    // 初始化方法
    copy(record) {
      this.visible = true
      this.recordData = record
      this.flowableCategoryList()
      this.getFlowableTypeList()

      this.$nextTick(() => {
        this.form.setFieldsValue({
          name: record.name,
          code: record.code,
          category: record.category,
          type: record.type + '',
          formUrl: record.formUrl,
          remark: record.remark
        })
      })

      // setTimeout(() => {
      //   this.form.setFieldsValue({
      //     name: record.name,
      //     code: record.code,
      //     category: record.category,
      //     type: record.type + '',
      //     formUrl: record.formUrl,
      //     remark: record.remark
      //   })
      // }, 100)
    },
    /**
     * 獲取分類
     */
    flowableCategoryList() {
      flowableCategoryList().then(res => {
        this.flowableCategoryListData = res.data
      })
    },

    /*
     * 獲取表單類型
     */
    getFlowableTypeList() {
      sysDictTypeDropDown({ code: 'form_resource_type' }).then(res => {
        this.flowableTypeListData = res.data
      })
    },
    handleSubmit() {
      const {
        form: { validateFields }
      } = this
      validateFields((errors, values) => {
        if (!errors) {
          this.confirmLoading = true
          values.formJson = this.recordData.formJson
          formResourceAdd(values)
            .then(res => {
              this.confirmLoading = false
              if (res.success) {
                this.$message.success('復制成功')
                this.$emit('ok', values)
                this.handleCancel()
              } else {
                this.$message.error('復制失敗：' + res.message)
              }
            })
            .finally(res => {
              this.confirmLoading = false
            })
        }
      })
    },
    handleCancel() {
      this.recordData = {}
      this.form.resetFields()
      this.visible = false
    }
  }
}
